import { MainLayout } from "../../layouts/MainLayout";
import Close from "../../components/close";
import { Container, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import formatPeriod from "../../utils/formatPeriod";
import { ToastContainer, toast } from "react-toastify";
import {
  useSubscribePayMutation,
  useCalculateCheckoutFeeMutation,
  useFetchCheckoutDetailsMutation

} from "../../store/services/smApi";
import { useHistory } from "react-router-dom";
import CheckoutSuccess from "../CheckoutSuccess/CheckoutSuccess";
import LoadingSpinner from "../../components/LoadingSpinner";
import { BsCheckCircleFill } from "react-icons/bs";
import { ImRadioUnchecked } from "react-icons/im";
import { RECURRING_UNITS, DISCOUNT_TYPES } from "../../utils/beDicts";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import FormatDate from "../../utils/formatDate";
import { prepare_errors } from "../../utils/formatError";
import { PaymentProvider } from "../Upgrade/Payment";
import useNext from "../../hooks/route.hook";
import debounce from "lodash.debounce";
import { useQuery } from "../../hooks/query.hook";

export default function Checkout (props) {
  const { t } = useTranslation(["translation"]);
  const isLoggedIn = useSelector((state) => state.userStore.isLoggedIn);
  const [planData, setPlanData] = useState({});
  const [checkoutFee, setCheckoutFee] = useState({});
  const [calculateCheckoutFee, { isLoading: isCalculatingCheckoutFee }] =
    useCalculateCheckoutFeeMutation();
  const [fetchCheckoutDetails, { isLoading: isFetchCheckoutDetails }] =
    useFetchCheckoutDetailsMutation();
  const [subscription, setSubscription] = useState({ title: "" });
  const [autorenew, setAutorenew] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [customPriceData, setCustomPriceData] = useState({
    price: 1,
    unit: 4,
    period: 1
  });
  const { query: { cost: costId, options }, appendQuery } = useQuery();
  const history = useHistory();

  const location = useLocation();

  const { nextUrl } = useNext();

  const searchParams = new URLSearchParams(location.search);

  // const costId = searchParams.get("cost");
  const user_subscription = searchParams.get("user-subscription");
  const editParam = searchParams.get("edit");
  const edit = editParam === null ? true : editParam.toLowerCase() === "true";

  // const options = searchParams.get("options")?.split(",").filter(op => op !== "") || [];

  const [selectedOptions, setSelectedOptions] = useState([]);
  // get info if 3ds
  const success = searchParams.get("success");
  const currency = useSelector(
    (state) => state.configStore.merchant.currency_code
  );
  const [paymentProviders, setPaymentProviders] = useState({
    isCheckoutEnabled: false,
    isArbEnabled: false,
    isTapEnabled: false,
    isTabbyEnabled: false,
    isMoyasarEnabled: false
  });

  const vat_setting = useSelector(
    (state) => state.configStore.merchant?.vat_setting
  );
  const [isCheckoutComPayment, setIsCheckoutComPayment] = useState(
    !!paymentProviders.isCheckoutEnabled
  );
  const [subscribePay, { isLoading: subscribePayIsLoading }] =
    useSubscribePayMutation();

  const get_change_handler = (handler) => {
    return edit ? handler : null;
  };
  const back = () => {
    window.history.back();
  };
  const go_home = () => {
    history.replace("/");
  };

  const handleChangeCost = (costId) => {
    appendQuery("cost", costId);
    const newPlanData = { ...planData };
    newPlanData.cost = newPlanData.plan.costs.find((c) => c.id === costId);
    setPlanData(newPlanData);
  };

  const handleCheckoutFee = async () => {
    if (!planData?.cost?.id) return;
    const body = {
      plan_cost_id: planData?.cost?.id,
      plan_options: selectedOptions?.map((o) => o.id),
      price: customPriceData.price || 1,
      unit: customPriceData.unit || 4,
      period: customPriceData.period || 1
    };
    if (couponCode) body.coupon_code = couponCode;
    const result = await calculateCheckoutFee({ body });
    if (result.data) {
      setCheckoutFee(result.data);
    } else if (result.error?.data) {
      Object.entries(result.error.data).forEach(([key, value]) =>
        toast.error(`${key}: ${value instanceof Array ? value[0] : value}`)
      );
      setCouponCode(null);
    } else {
      toast.error(t("something went wrong"));
      setCouponCode(null);
    }
  };

  const handlePayment = async (type, token = null, schema_local = null, return_data) => {
    const body = {
      subscription: planData.cost.id,
      type,
      token,
      schema_local,
      payment_source: null,
      autorenewal: autorenew,
      // payment_source: selectedPaymentSource,
      total: checkoutFee.total_after_vat,
      options: selectedOptions.map((o) => o.id),
      coupon_code: couponCode,
      user_subscription,
      recurrence_unit: customPriceData.unit || 4,
      recurrence_period: customPriceData.period || 1,
      price: customPriceData.price || 1
    };
    const result = await subscribePay({ body });
    if (result && result.data) {
      if (result.data.redirect_url) {
        window.location.replace(result?.data?.redirect_url);
      } else if (return_data) {
        return result.data;
      } else {
        toast.error(t("something went wrong"));
      }
    } else if (result?.error?.data?.error) {
      toast.error(result?.error?.data?.error);
    } else if (result && result.error) {
      const msg = result?.error?.data?.detail
        ? result.error?.data?.detail
        : result.error.data.detail
          ? typeof result.error.data.errors === "string"
            ? result.error?.data?.errors
            : Object.values(result.error.data.errors).join(" , ")
          : "";
      toast.error(`${t("Error")}  ${msg}`);

      if (result.error.status === 404) {
        //  delay 2 sec to redirect to home
        notify_and_return(t("Plan not found"));
        return;
      }
      if (result.error.status === 401) {
        history.push(`/login?next=${nextUrl}`);
      }

      if (result?.error?.data?.error) {
        toast.error(result?.error?.data?.error);
      } else if (result && result.error) {
        const msg = prepare_errors(result.error);
        toast.error(`${t("Error")}  ${msg}`);

      // means plan not active or not exist or ...
      }
    }
  };

  const plan_option_Handler = (index) => (e) => {
    console.log("planData.plan[\"plan_options\"]");
    console.log(planData.plan.plan_options);

    let newOptions;
    if (e.target.checked) {
      // we will add it if not exist
      const exists = selectedOptions.findIndex((el) => el.id === index);

      if (!(exists === -1)) {
        return;
      }
      newOptions = [...selectedOptions];
      newOptions.push(
        planData.plan.plan_options[
          planData.plan.plan_options.findIndex((el) => el.id === index)
        ]
      );
    } else {
      newOptions = [...selectedOptions];
      const el_index = selectedOptions.findIndex((el) => el.id === index);
      if (el_index > -1) {
        // only splice array when item is found
        newOptions.splice(el_index, 1); // 2nd parameter means remove one item only
      }
    }
    setSelectedOptions(newOptions);
  };
  const notify_and_return = (msg) => {
    toast.error(msg);
    setTimeout(() => {
      go_home();
    }, 1500);
  };
  const getPlanData = async () => {
    if (!costId) {
      notify_and_return(t("Plan not found"));
      return;
    }
    const result = await fetchCheckoutDetails(costId);

    if (result.data) {
      let costSelected;
      let planSelected;
      result.data.plans.forEach((plan) => {
        plan.costs.forEach((cost) => {
          if (cost.id === costId) {
            costSelected = cost;
            planSelected = plan;
          }
        });
      });

      setPlanData({ ...result.data, plan: planSelected, cost: costSelected });
      console.log("options", options);
      if (options.length > 0) {
        setSelectedOptions(
          planSelected.plan_options.filter((option) =>
            options.includes(option.id.toString())
          )
        );
      }
      setPaymentProviders({
        isCheckoutEnabled: result.data?.payment_providers?.checkout,
        isArbEnabled: result.data?.payment_providers?.arb,
        isTapEnabled: result.data?.payment_providers?.tap,
        isTabbyEnabled: result.data?.payment_providers?.tabby,
        isMoyasarEnabled: result.data?.payment_providers?.moyasar
      });
      setIsCheckoutComPayment(result.data?.payment_providers?.checkout);
    } else {
      notify_and_return(`${t("something went wrong")} ${prepare_errors(result.error)}`);
    }
  };

  useEffect(() => {
    setSubscription(planData.subscription);
    // openCheckoutCards();
    // if redirected back from 3ds
  }, [planData, paymentProviders]);

  useEffect(() => {
    if (success !== "true") {
      handleCheckoutFee();
    }
  }, [selectedOptions, couponCode, planData]);

  const customPriceDataDebounce = debounce(async () => {
    await handleCheckoutFee();
  }, 1000);

  useEffect(() => {
    customPriceDataDebounce();
  }, [customPriceData.price]);

  useEffect(() => {
    if (success !== "true") {
      getPlanData();
    }
  }, [costId]);

  return (
    <>
      <Modal
        show={!!success}
        onHide={go_home}
        backdrop={true}
        keyboard={false}
        size="lg"
        autoFocus
        centered
      >
        <CheckoutSuccess />
      </Modal>
      <ToastContainer />
      <MainLayout>
        {
          ((planData?.subscription?.id) || isFetchCheckoutDetails) ?? <LoadingSpinner />
        }
        {
          planData?.subscription && planData?.plan && planData?.cost && (
            <Container>
              <div className="row py-4 py-lg-5  justify-content-center">
                <div className="col-lg-8">
                  {isCalculatingCheckoutFee
                    ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <LoadingSpinner />
                      </div>
                      )
                    : (
                      <div className="ui-card">
                        <div className="">
                          {
                            edit && (
                              <Close>
                                <div className="cursor-pointer close-text" onClick={back}>
                                  {t("change plan")}
                                </div>
                              </Close>
                            )
                          }

                          <p className="card-title-bigger mb-12">
                            {subscription?.title}
                          </p>
                          <p className="card-info mt-0 mb-12">
                            {t("plan")} : {planData?.plan?.name}
                          </p>

                          <p className="mb-16">
                            {
                                planData?.cost?.type === "custom"
                                  ? <span className="card-info-bolder color-text">
                                    {t("Name Your Own Price")}
                                  </span>
                                  : (
                                    <>
                                      <span className="card-info-bolder color-text">
                                        {parseFloat(planData?.cost?.full_vat_data?.total_after_vat).toFixed(2)}{" "}
                                        {t(planData?.cost?.display_currency_code)}{" "}
                                      </span>
                                      <span className="card-info color-info">
                                        /
                                        {formatPeriod(
                                          planData?.cost?.recurrence_unit,
                                          planData?.cost?.recurrence_period,
                                          t,
                                          false
                                        )}
                                      </span>
                                    </>

                                    )
                              }
                            {
                          +checkoutFee?.setup_fee_before_discount > 0 && (
                            <span className="fw-bold small text-black ms-1">
                              {`( + ${planData?.cost.setup_fee_with_vat} ${t(
                              planData?.cost.display_currency_code
                            )} ${t("Setup Fee")} )`}
                            </span>
                          )}
                          </p>
                          <p className="card-info color-info">
                            {planData?.plan.html_content}
                          </p>
                          {planData?.cost.billing_cycle !== "forever" && (
                            <p className="card-info text-black">
                              {t("Total Subscription Length")} :{" "}
                              {planData?.cost.billing_cycle_count *
                            planData?.cost.recurrence_period}{" "}
                              {t(
                                (
                                  RECURRING_UNITS[planData?.cost.recurrence_unit] +
                              (planData?.cost.billing_cycle_count *
                                planData?.cost.recurrence_period >
                              1
                                ? "s"
                                : "")
                                ).toLowerCase()
                              )}{" "}
                              {`(${planData?.cost.billing_cycle_count} ${t(
                            "Billing Cycles"
                          )})`}
                            </p>
                          )}
                        </div>
                        {
                          planData?.cost.type === "custom" && (
                            <div className="mt-24">
                              <span className="card-info color-info">
                                {t("Please set your custom price below")}
                              </span>
                              <div className="d-flex flex-row align-items-start justify-content-start gap-2 flex-wrap flex-md-nowrap w-100 mt-12">
                                <div className="d-flex flex-column align-items-start justify-content-start gap-1">
                                  <label className="form-label" htmlFor="custom_price">
                                    {t("Price")}
                                  </label>
                                  <input
                                    className="form-control"
                                    // style={{ maxWidth: "240px" }}
                                    type="number"
                                    name="custom_price"
                                    id="custom_price"
                                    onChange={(e) => setCustomPriceData({ ...customPriceData, price: e.target.value })}
                                    value={customPriceData.price}
                                    placeholder={t("Enter your price")}
                                  />
                                </div>
                                <div className="d-flex flex-column align-items-start justify-content-start gap-1">
                                  <label className="form-label" htmlFor="custom_unit">
                                    {t("Recurrence")}
                                  </label>
                                  <select
                                    className="form-select"
                                    // style={{ maxWidth: "240px" }}
                                    name="custom_unit"
                                    id="custom_unit"
                                    onChange={(e) => setCustomPriceData({ ...customPriceData, unit: e.target.value })}
                                    value={customPriceData.unit}
                                  >
                                    <option value="" disabled selected>
                                      {t("Select Recurrence Unit")}
                                    </option>
                                    {Object.entries(RECURRING_UNITS).filter(
                                      ([key]) => key > 3
                                    ).map(([key, value]) => (
                                      <option key={key} value={key}>
                                        {t(value)}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="d-flex flex-column align-items-start justify-content-start gap-1">
                                  <label className="form-label" htmlFor="custom_period">
                                    {t("Period")}
                                  </label>
                                  <input
                                    className="form-control"
                                    // style={{ width: "auto" }}
                                    type="number"
                                    name="custom_period"
                                    id="custom_period"
                                    onChange={(e) => setCustomPriceData({ ...customPriceData, period: e.target.value })}
                                    value={customPriceData.period}
                                    placeholder={t("Period")}
                                  />
                                </div>
                              </div>
                              <span className="card-info color-info">
                                {t("Total")} :{" "}
                                {parseFloat(
                                  checkoutFee?.total_after_vat
                                ).toFixed(2)}{" "}
                                {t(currency)}
                              </span>
                              / <span className="card-info color-info">
                                {formatPeriod(
                                  customPriceData.unit,
                                  customPriceData.period,
                                  t,
                                  false
                                )}
                              </span>

                            </div>
                          )
                        }
                        <div className="mt-24 d-flex flex-column align-items-start justify-content-start gap-2">
                          <p className="card-title-bigger color-text m-0">
                            {t("plan features")}
                          </p>
                          {planData?.plan.plan_features.map((option, idx) => (
                            <div
                          key={`option${idx}`}
                          className="d-flex gap-2 align-items-center"
                        >
                              {option.is_active
                                ? (
                                  <BsCheckCircleFill className="color-primary" />
                                  )
                                : (
                                  <ImRadioUnchecked className="color-primary" />
                                  )}

                              <span className="feat-text">
                                {option.name} {option.value}
                              </span>
                            </div>
                          ))}
                        </div>
                        {planData?.plan.plan_options.length > 0 && planData?.cost.type !== "custom" && (
                          <div className="mt-24 d-flex flex-column align-items-start justify-content-start gap-2">
                            {planData?.plan?.plan_options?.length > 0 && (
                              <p className="card-title-bigger color-text m-0">
                                {t("Plan Addons")}
                              </p>
                            )}

                            <ul className="list-style-none m-0">
                              {planData?.plan.plan_options.map((plan_option) => (
                                <>
                                  <li className="pt-2">
                                    <div className="form-check">
                                      <input
                                  className="form-check-input flex-shrink-0 font-20"
                                  type="checkbox"
                                  onChange={get_change_handler(plan_option_Handler(plan_option.id))}
                                  id={plan_option.id}
                                  checked={
                                    selectedOptions.findIndex(
                                      (el) => el.id === plan_option.id
                                    ) !== -1
                                  }
                                />
                                      <label
                                    className="form-check-label mb-0"
                                    htmlFor={plan_option.id}
                                  >
                                        {plan_option.name}:{" "}
                                        <strong>
                                          {t(currency)}{" "}
                                          {parseFloat(
                                            plan_option.full_vat_data
                                              .total_after_vat
                                          ).toFixed(2)}
                                        </strong>
                                      </label>
                                    </div>
                                  </li>
                                </>
                              ))}
                            </ul>
                          </div>
                        )}
                        {planData?.plan.costs.length > 1 && (
                          <div className="mt-24 d-flex flex-column align-items-start justify-content-start gap-2">
                            <p className="color-text m-0">
                              {t(
                                "You can change your subscription cost from below options"
                              )}
                            </p>
                            <ul className="list-style-none m-0">
                              {planData?.plan.costs.map((cost) => (
                                <li className="pt-2" key={cost.id}>
                                  <div className="form-check p-2 border rounded m-0 bg-white">
                                    <input
                              className="form-check-input flex-shrink-0 font-20 ms-0 me-2"
                              type="radio"
                              name="flexRadioDefault"
                              id={cost.id}
                              onChange={get_change_handler(() => handleChangeCost(cost.id))}
                              checked={cost.id === planData?.cost.id}
                            />
                                    <label
                                  className="form-check-label mb-0"
                                  htmlFor={cost.id}
                                >
                                      {
                                    cost.type === "custom"
                                      ? (
                                        <strong>
                                          {t("Name Your Own Price")}
                                        </strong>
                                        )
                                      : (
                                        <>
                                          <strong>
                                            {parseFloat(
                                              cost.full_vat_data.total_after_vat
                                            ).toFixed(2)}{" "}
                                            {t(currency)}
                                          </strong>
                                          <span className="card-info color-info">
                                            /
                                            {formatPeriod(
                                              cost.recurrence_unit,
                                              cost.recurrence_period,
                                              t,
                                              false
                                            )}
                                          </span>
                                          {+cost.setup_fee > 0 && (
                                            <span className="fw-bold small text-black ms-1">
                                              {
                                            `( + ${cost.setup_fee_with_vat} ${t(
                                            currency
                                            )} ${t("Setup Fee")} )`
                                          }
                                            </span>
                                          )}
                                        </>
                                        )
                                  }

                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {planData?.subscription.auto_renewal_option === "op" && (
                          <div className="mt-24 d-flex flex-column align-items-start justify-content-start gap-2">
                            <div className="ps-2">
                              <div className="form-check">
                                <input
                            className="form-check-input flex-shrink-0 font-20"
                            type="checkbox"
                            name="flexRadioDefault"
                            id="autorenewal"
                            onChange={get_change_handler(() => setAutorenew(!autorenew))}
                            checked={autorenew}
                          />
                                <label
                            className="form-check-label mb-0"
                            htmlFor="autorenewal"
                          >
                                  <strong>{t("Auto renew subscription")}</strong>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {vat_setting?.vat_enable && (
                          <div className="mt-24">
                            <p className="card-info-bolder color-text">
                              {t("Vat Number")} : {vat_setting.vat_number}
                            </p>
                          </div>
                        )}
                      </div>
                      )}
                  {planData?.plan.has_active_coupon_promotion && (
                    <div>
                      <div className="mt-2 row">
                        <div className="col-lg-6 col-md-8">
                          <Formik
                          initialValues={{ key: "" }}
                          validate={(values) => {
                            const errors = {};
                            if (!values.key) {
                              errors.key = "key must be set";
                            }
                            return errors;
                          }}
                          onSubmit={async (values, { setSubmitting }) => {
                            // await checkCoupon(values);
                            setCouponCode(values.key);
                          }}
                        >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                            /* and other goodies */
                            }) => (
                              <form
                              onSubmit={handleSubmit}
                              className="input-group coupon-form"
                              style={{
                                alignItems: "center",
                                padding: "8px"
                              }}
                            >
                                <input
                            type="text"
                            name="key"
                            onChange={get_change_handler(handleChange)}
                            onBlur={handleBlur}
                            value={values.key}
                            className="coupon-placeholder form-control coupon-input-text ml-2"
                            // disabled={!!coupon.id}
                            placeholder={t("enter coupon code")}
                          />
                                {errors.coupon && touched.coupon && errors.coupon}
                                <div className="input-group-append">
                                  {couponCode
                                    ? (

                                      <button
                                    className="ms-2   btn-sm btn-light btn-outer btn-sm"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          `${t(
                                            "Are you sure you wish to delete this"
                                          )} ${t("coupon")} ?`
                                        )
                                      ) {
                                        //  deleteCoupon();
                                        setCouponCode(null);
                                        setFieldValue("key", "");
                                      }
                                    }}
                                  >
                                        <FontAwesomeIcon icon={faTimes} />
                                      </button>
                                      )
                                    : (
                                      <button
                                    type="submit"
                                    className="btn btn-lg  btn-primary mr-8 form-control"
                                    disabled={isSubmitting}
                                  >
                                        {t("apply coupon")}
                                      </button>
                                      )}
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-4 ui-card-outer pd-20">
                  <div className="mt-20 card-info-bolder">
                    <p className="card-sub-title mb-20">{t("Price Summery")}</p>
                  </div>
                  <div className="divider"></div>
                  {isCalculatingCheckoutFee
                    ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <LoadingSpinner />
                      </div>
                      )
                    : (
                      <div>
                        <div className="mt-20 d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">{t("Price")}</p>
                          <p>
                            <strong>
                              {" "}
                              {parseFloat(
                                checkoutFee?.plan_before_discount
                              ).toFixed(2)}{" "}
                              {t(currency)}{" "}
                              {/* <span className="card-info color-info fs-16">
                                /
                                {formatPeriod(
                                  planData?.cost.recurrence_unit,
                                  planData?.cost.recurrence_period,
                                  t,
                                  false
                                )}
                              </span> */}
                            </strong>
                          </p>
                        </div>
                        {
                        checkoutFee?.discount_type !== DISCOUNT_TYPES.FIXED && (
                          <>
                            {checkoutFee?.setup_fee_before_discount > 0 && (
                              <div className="mt-20 d-flex align-content-center justify-content-between">
                                <p className="summery-item-title">{t("Setup Fee")} </p>
                                <p>
                                  <strong>
                                    {" "}
                                    {parseFloat(
                                      checkoutFee?.setup_fee_before_discount
                                    ).toFixed(2)}{" "}
                                    {t(currency)}{" "}
                                  </strong>
                                </p>
                              </div>
                            )}
                            {
                      checkoutFee?.options_before_discount > 0 && (
                        <div className="mt-20 d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">{t("Plan Addons")} </p>
                          <p>
                            <strong>
                              {" "}
                              {parseFloat(
                                checkoutFee?.options_before_discount
                              ).toFixed(2)}{" "}
                              {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                      )
                    }
                            {
                      (checkoutFee.discount_amount ||
                      checkoutFee.discount_percentage ||
                      checkoutFee.vat_enable) && (
                        <div className="mt-20 mb-20 d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">
                            {checkoutFee.discount_amount ||
                          checkoutFee.discount_percentage
                              ? t("Total Before Discount")
                              : t("Total before VAT")}{" "}
                          </p>
                          <p>
                            <strong>
                              {" "}
                              {checkoutFee.total_before_discount} {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                      )
                    }
                            {(checkoutFee?.discount_percentage ||
                    checkoutFee.discount_amount) && (
                      <>
                        {checkoutFee.discount_percentage && (
                          <>
                            <div className="mt-20  d-flex align-content-center justify-content-between">
                              <p className="summery-item-title">
                                {t("Discount Percentage")}{" "}
                              </p>
                              <p>
                                <strong>
                                  {" "}
                                  -{checkoutFee?.discount_percentage} %{" "}
                                </strong>
                              </p>
                            </div>
                            <div className="mt-20  d-flex align-content-center justify-content-between">
                              <p className="summery-item-title">
                                {t("Discount On")}{" "}
                              </p>
                              <p>
                                <strong>
                                  {" "}
                                  {t(checkoutFee?.discount_level_display)}{" "}
                                </strong>
                              </p>
                            </div>
                            {["1", "2"].includes(checkoutFee?.discount_level) && checkoutFee.plan_after_discount > 0 && (
                              <>
                                <div className="mt-20  d-flex align-content-center justify-content-between">
                                  <p className="summery-item-title">
                                    {t("Plan price after discount")}{" "}
                                  </p>
                                  <p>
                                    <strong>
                                      {" "}
                                      {checkoutFee?.plan_after_discount} {t(currency)}{" "}
                                    </strong>
                                  </p>
                                </div>
                                {
                                +checkoutFee?.setup_fee_after_discount > 0 && (
                                  <div className="mt-20  d-flex align-content-center justify-content-between">
                                    <p className="summery-item-title">
                                      {t("Setup Fee after discount")}{" "}
                                    </p>
                                    <p>
                                      <strong>
                                        {" "}
                                        {checkoutFee?.setup_fee_after_discount} {t(currency)}{" "}
                                      </strong>
                                    </p>
                                  </div>
                                )
                              }
                              </>
                            )}
                            {["2", "3"].includes(checkoutFee?.discount_level) && checkoutFee.options_after_discount > 0 && (
                              <div className="mt-20  d-flex align-content-center justify-content-between">
                                <p className="summery-item-title">
                                  {t("Discount Percentage")}{" "}
                                </p>
                                <p>
                                  <strong>
                                    {" "}
                                    -{checkoutFee?.discount_percentage} %{" "}
                                  </strong>
                                </p>
                              </div>
                            )}
                          </>
                        )}
                        <div className="mt-20  d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">
                            {t("Discount Amount")}{" "}
                          </p>
                          <p>
                            <strong>
                              {" "}
                              -{checkoutFee?.discount_amount} {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                        <div className="mt-20  d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">
                            {t(!checkoutFee.vat_included ? "Total After Discount" : "Total Before VAT")}{" "}
                          </p>
                          <p>
                            <strong>
                              {" "}
                              {checkoutFee?.total_before_vat} {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                      </>
                            )}
                          </>
                        )
                      }
                        {
                        checkoutFee?.discount_type === DISCOUNT_TYPES.FIXED && (
                          <>
                            <div className="mt-20  d-flex align-content-center justify-content-between">
                              <p className="summery-item-title">
                                {t("Free Period")}{`(${checkoutFee?.period_type === "1" ? t("In The Beginning") : t("At The End")})`}{" "}
                              </p>
                              <p>
                                <strong>
                                  {formatPeriod(
                                    checkoutFee?.free_period_unit,
                                    checkoutFee?.free_period,
                                    t,
                                    false
                                  )}
                                </strong>
                              </p>
                            </div>
                            {
                              checkoutFee?.period_type === "1" && (
                                <div className="mt-20  d-flex align-content-center justify-content-between">
                                  <p className="summery-item-title">
                                    {t("Total After Free Period")}{" "}
                                  </p>
                                  <p>
                                    <strong>
                                      {" "}
                                      {checkoutFee?.next_billing_amount} {t(currency)}{" "}
                                    </strong>
                                  </p>
                                </div>
                              )
                            }

                            <div className="mt-20  d-flex align-content-center justify-content-between">
                              <p className="summery-item-title">
                                {t("Next Billing Date")}{" "}
                              </p>
                              <p>
                                <strong>
                                  {" "}
                                  {FormatDate(checkoutFee?.next_billing_date, "DD/MM/YYYY")}{" "}
                                </strong>
                              </p>
                            </div>

                          </>
                        )
                      }
                        {checkoutFee.vat_enable && (
                          <div className="mt-20 d-flex align-content-center justify-content-between">
                            <p className="summery-item-title">{t("VAT")} </p>
                            <strong>
                              {" "}
                              {checkoutFee.vat_amount} {t(currency)} (
                              {checkoutFee.vat_percentage}% ){" "}
                            </strong>
                          </div>
                        )}
                        <div className="mt-20 mb-20 d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">{t("Total")} </p>
                          <p>
                            <strong>
                              {" "}
                              {checkoutFee.total_after_vat} {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                      </div>
                      )}
                  <hr className="my-5" />
                  <PaymentProvider
                  currency={currency}
                  handlePayment={handlePayment}
                  isArbEnabled={paymentProviders.isArbEnabled}
                  isCheckoutComPayment={isCheckoutComPayment}
                  isCheckoutEnabled={paymentProviders.isCheckoutEnabled}
                  isLoggedIn={isLoggedIn}
                  isTabbyEnabled={paymentProviders.isTabbyEnabled}
                  isTapEnabled={paymentProviders.isTapEnabled}
                  isMoyasarEnabled={paymentProviders.isMoyasarEnabled}
                  setIsCheckoutComPayment={setIsCheckoutComPayment}
                  amount={checkoutFee?.total_after_vat ?? 0}
                  upgradeRequestIsLoading={subscribePayIsLoading}
                  isUpdateMethod={false}
                />
                </div>
              </div>
            </Container>
          )}
      </MainLayout>
    </>
  );
}
